#main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-fluid, #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-lg, #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-md, #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-sm, #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-xl, #main-wrapper[data-layout=horizontal][data-sidebar-position=fixed] .body-wrapper>.container-xxl {
    padding-top: 100px!important;
}

.business-form-map-cover {
    position: relative;
}

    .business-form-map {
        height: 300px;
        width: 100%;
    }

    .business-form-map-cover .on-map-toolbox {
        z-index: 1000;
        top: 16px;
        right: 16px;
        position: absolute;
    }

        .business-form-map-cover .on-map-toolbox .btn-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
        }

/*** Reviews Rules Stats ***/

table.reviews-rules-stats th, table.reviews-rules-stats td {
    text-align: center;
}

table.reviews-rules-stats td {
    cursor: pointer;
}

table.reviews-rules-stats tr td:nth-child(1) {
    width: 130px;
}

    table.reviews-rules-stats tr td:nth-child(1) div {
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 5px;
    }

.business-media-nav {
    margin: 0 0 15px 0;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: stretch;
    flex-wrap: wrap;
    gap: 10px;
}

    .business-media-nav a {
        white-space: nowrap;
        flex-grow: 1;
    }

.drop-area {
    padding: 20px 20px;
    border: 1px dotted rgba(0,0,0,.3);
    background: rgba(0,0,0,0.03);

    min-height: 150px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}
.drop-area.active {
    border-color: rgb(93, 135, 255);
    background: rgba(93, 135, 255,0.03);
    color: rgb(93, 135, 255);
}

.w-xl-100 {
    width: auto!important;
}

.app-header .navbar .navbar-nav .nav-item .nav-link {
    padding: 6px 16px 10px 16px;
}

aside.left-sidebar .fixed-profile {
    position: fixed;
    bottom: 15px;
}