@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulseFade {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

:root {
  --bs-body-font-family: "Plus Jakarta Sans", "Open Sans", Arial, sans-serif;
}

.p-small-margins p {
    margin-bottom: 0.5rem;
}

.form-check-input {
    cursor: pointer;
}

.m-block-gap {
    margin-bottom: 30px;
}

.table-heading-sortable {
    display: flex;
    align-items: center;
    gap: 7px;

    cursor: pointer;
}

    .table-heading-sortable i.ti {
        margin-top: 2px;
    }

.publication-card-media-container {
    background-color: var(--bs-light);
    border-radius: var(--bs-card-border-radius) var(--bs-card-border-radius) 0 0;
    width: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;
    position: relative;
}

    .publication-card-media-container img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
}

.card.card-sq-image a.sq-image {
    width: 100%;
    position: relative;
    display: block;
}
.card.card-sq-image a.sq-image::before {
    content: "";
    display: block;
    padding-top: 59%;
}

    .card.card-sq-image a.sq-image img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

.card-add {
    height: calc(100% - 30px);
}

    .card-add i.ti {
        font-size: 120px;
    }

.one-liner {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    position: relative;
}
.one-liner::after {
    background: linear-gradient(to left, white, transparent);
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 100%;
}

.business-top-profile {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 200px;
}

.editable, .editable * {
    cursor: pointer !important;
}
.editable:hover {
    background-color: var(--bs-light);
}
.d-editable {visibility: hidden;}
.editable:hover .d-editable {visibility: visible;}

.bordered-tip {
    padding-left: 1.5rem;
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    border-bottom: 0;
}
@media screen and (max-width: 768px) {
    .bordered-tip {
        padding-left: 0;
        border-left: 0;
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }
}

table tr.row-passive {
    opacity: 0.3;

    transition: opacity 0.3s;
}
table tr.row-passive:hover {
    opacity: 1;
}

.table-primary {
    --bs-table-color: #ffffff;
    --bs-table-bg: rgb(93, 135, 255);
    --bs-table-active-bg: rgb(93, 135, 255);
    --bs-table-active-color: #ffffff;
    --bs-table-hover-bg: rgb(93, 135, 255);
    --bs-table-hover-color: #ffffff;
}

.k-simple-form {
    max-width: 800px;
}

.image-upload-thumbnail {
    width: 32px;
    height: 32px;
}

    .image-upload-thumbnail img {
        width: 32px;
        height: 32px;

        object-fit: contain;
        object-position: 50% 50%;
    }

.card.loading table {
    animation: pulseFade 2s ease-in-out infinite;
}

.card.loading::after {
    content: "";
    background-color: rgba(255,255,255,0.75);

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    position: absolute;
}

.card.loading .card-loading {
    margin: -16px 0 0 -16px;

    width: 32px;
    height: 32px;

    animation: spin 2s linear infinite;

    top: 50%;
    left: 50%;
    position: absolute;
    z-index: 10;
}

    .card.loading .card-loading svg {
        width: 100%;
        height: 100%;
    }

        .card.loading .card-loading svg path {
            fill: var(--bs-primary);
        }

.card .project-logo {
    border: 3px #fff solid;
    border-radius: 35px;

    width: 70px;
    height: 70px;
    display: block;

    object-fit: cover;
    object-position: center;

    left: 14px;
    bottom: -35px;
    position: absolute;

    overflow: hidden;
}


.show-on-tr-hover {
    opacity: 0;

    transition: opacity 0.3s;
}

tr:hover .show-on-tr-hover {
    opacity: 1;
}